<template>
  <div class="message__container">
    <div
      class="message__header"
      :class="showColumn && 'message__header-column'"
    >
      <div class="message__header-item">
        <data-card-small
          :theme="'yellow'"
          :text="$t('messages.queue')"
          :value="queue"
        >
          <message-text-icon />
        </data-card-small>
        <data-card-small
          :theme="'blue'"
          :text="$t('messages.registers')"
          :value="pagination.total"
        >
          <message-text-icon />
        </data-card-small>
      </div>
      <div class="message__header-item">
        <button-filter
          ref="filter"
          :display="displayFilters"
          @change="onFiltersChanged"
        >
          <hcc-select
            :placeholder="$t('messages.status-all')"
            custom-class="select"
            v-model="statu"
            :options="status"
            optionLabel="title"
            @select="selectStatus"
          />
        </button-filter>
        <hcc-button-icon
          class="message__item"
          v-tooltip="$t('messages.clear')"
          color="alert"
          @click="clear"
        >
          <trash-can-icon />
        </hcc-button-icon>
      </div>
    </div>
    <filters
      :display="[...displayFilters, 'status']"
      :status="selectedStatus"
      :date="date"
      :agents="selectedAgents"
      :channel="selectedChannels"
    />
    <messages-table
      @pageChange="pageChange"
      @clientSearch="findByClientInfo"
      :data="rows"
      :actualPage="actualPage"
      :pagination="pagination"
      :campaingId="campaingId"
      :loading="loading"
    />
  </div>
</template>

<script>
import { mapGetters, createNamespacedHelpers } from 'vuex';
import campaingQueue from '@/graphql/queries/conversations/queueByCampaign.gql';
import listMessengerByConversation from '@/graphql/queries/conversations/listConversationHistory.gql';
import messagesGql from '@/graphql/suscriptions/messages.gql';
import queueCounter from '@/graphql/suscriptions/queueCount.gql';
import executeQuery from '@/utils/gql-api';
import EventBus from '@/eventBus';
import { REPORT_MESSAGES_REQUEST } from '@/eventTypes';
import Filters from '@/components/Filters.vue';

const { mapState, mapMutations } = createNamespacedHelpers('chat');

export default {
  components: {
    Filters,
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
    MessagesTable: () => import('@/components/MessagesTable.vue'),
    HccButtonIcon: () => import('@/components/shared/HccButtonIcon/index.vue'),
    DataCardSmall: () => import('@/components/DataCardSmall.vue'),
    ButtonFilter: () => import('@/components/ButtonFilter.vue'),
  },
  data() {
    return {
      loading: false,
      pagination: {
        total: 0,
        page: 1,
      },
      query: null,
      filters: [],
      queue: 0,
      rows: [],
      searchText: '',
      statu: null,
      displayFilters: ['campaign', 'agent', 'channel', 'date'],
      actualPage: 1,
      windowWidthMsg: window.innerWidth,
      selectedStatus: null,
      selectedAgents: [],
      selectedChannels: '',
      date: {},
    };
  },
  computed: {
    ...mapGetters({
      isOwner: 'shared/isOwner',
      user: 'shared/userInfo',
      language: 'shared/getLanguage',
    }),
    ...mapState({
      currentConversationId: state => state.activeClientId,
    }),
    campaingId() {
      return this.filters ? this.filters.campaignId : 0;
    },
    suscriptionArgs() {
      if (this.filters) {
        return {
          companyId: this.filters.companyId,
          campaignId: this.filters.campaignId,
        };
      }
      return { companyId: this.user.company };
    },
    showColumn() {
      return this.windowWidthMsg <= 1280;
    },
    status() {
      return [
        {
          title: this.$t('messages.assigned'),
          icon: 'timer-sand-icon',
          color: 'one',
          id: 2,
        },
        {
          title: this.$t('messages.process'),
          icon: 'circle-icon',
          color: 'two',
          id: 3,
        },
        {
          title: this.$t('messages.resolved'),
          icon: 'check-circle',
          color: 'three',
          id: 4,
        },
        {
          title: this.$t('messages.cancel'),
          icon: 'close-circle-icon',
          color: 'four',
          id: 5,
        },
        {
          title: this.$t('messages.out-of-time'),
          icon: 'clock-outline-icon',
          color: 'five',
          id: 6,
        },
        {
          title: this.$t('messages.disconnected'),
          icon: 'account-off-outline-icon',
          color: 'five',
          id: 7,
        },
        {
          title: this.$t('messages.ivr'),
          icon: 'webchat',
          color: 'six',
          id: 8,
        },
        {
          title: this.$t('filter.all'),
          id: -1,
        },
      ];
    },
  },
  watch: {
    filters(newValue, oldValue) {
      if (newValue.campaignId !== oldValue.campaignId) {
        this.fetchQueue();
      }
      if (oldValue === null
        || newValue.companyId !== oldValue.companyId
        || newValue.campaignId !== oldValue.campaignId
        || newValue.channelId !== oldValue.channelId
        || newValue.agentId !== oldValue.agentId
        || newValue.startDate !== oldValue.startDate) {
        this.loading = true;
        this.fetchMessenger(newValue);
      }
    },
    statu() {
      this.fetchMessenger(this.filters);
    },
    language() {
      if (this.statu) {
        this.selectedStatus = this.status.find(status => status.id === this.statu.id)?.title;
        this.statu = this.status.find(status => status.id === this.statu.id);
      }
    },
  },
  created() {
    EventBus.$on(REPORT_MESSAGES_REQUEST, () => {
      this.$toasted.global.success(this.$t('reports.success'));
    });
  },
  mounted() {
    if (this.isOwner) {
      this.displayFilters.push('company');
    }

    window.addEventListener('resize', this.updateWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWidth);
  },
  apollo: {
    $subscribe: {
      newCampaignConversation: {
        query: messagesGql,
        variables() {
          return { ...this.suscriptionArgs };
        },
        result({ data }) {
          const { newCampaignConversation: conversation } = data;
          const index = this.rows.findIndex(({ id }) => id === conversation.id);
          if (index !== -1 || this.satisfyFilters(conversation)) {
            this.findAndUpdate(conversation, index);
          }
          if (conversation.id === this.currentConversationId) {
            this.updateConversation(conversation);
          }
        },
        error(err) {
          this.networkErr(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        },
      },
      queueCounter: {
        query: queueCounter,
        variables() {
          return { ...this.suscriptionArgs };
        },
        result({ data }) {
          const { queueCounter: totals } = data;
          const id = this.filters.campaignId;
          if (id === totals.campaign) {
            this.queue = totals.total;
          }
        },
      },
    },
  },
  methods: {
    ...mapMutations(['updateConversation']),
    async fetchQueue() {
      const id = this.filters.campaignId;
      const { total } = await executeQuery(
        'campaingQueue', campaingQueue, {
          campaign: id,
          company: this.filters.companyId,
        }, false,
      );
      this.queue = total;
    },
    async fetchMessenger(filters, page = 1) {
      this.loading = true;

      this.query = {
        companyId: filters.companyId,
        campaignId: filters.campaignId,
        channelId: filters.channelId,
        agents: filters.agentId || filters.agents,
        status: this.statu && this.statu.id !== -1 ? [this.statu.id] : null,
        start: filters.startDate || filters.start,
        end: filters.endDate || filters.end,
        text: this.searchText,
        length: 10,
        page,
      };

      const response = await executeQuery(
        'listMessengerByConversation',
        listMessengerByConversation,
        this.query,
        false,
      );


      this.rows = response.data || [];
      this.pagination.total = response.total;
      this.pagination.page = response.page || 1;
      this.loading = false;
    },
    pageChange(newPage) {
      this.actualPage = newPage;
      this.fetchMessenger(this.query, newPage);
    },
    clear() {
      this.statu = null;
      this.actualPage = 1;
      this.$refs.filter.resetValues();
    },
    onFiltersChanged(filters) {
      this.filters = filters;
      this.date = { start: filters.startDate, end: filters.endDate };
      if (filters.agentId) {
        this.selectedAgents = filters.agentId;
      } else {
        this.selectedAgents = [];
      }
      if (filters.channelId) {
        this.selectedChannels = filters.channelId;
      } else {
        this.selectedChannels = '';
      }
    },
    satisfyFilters(conversation) {
      const {
        agent,
        channel,
        status,
        client,
        campaign,
        createdAt,
      } = conversation;
      const {
        channelId,
        agents,
        status: statu,
        end,
        campaignId,
      } = this.query;

      const isAgent = agents ? agents.includes(agent?.id) : true;
      const isStatus = statu ? statu === status : true;
      const isChannel = channelId ? channel.id === channelId : true;
      const isDateRange = end ? this.isInsideRange(createdAt, end) : true;
      const isSameCampaign = campaignId ? campaign.id === campaignId : true;

      return isAgent
        && isStatus
        && isChannel
        && isDateRange
        && isSameCampaign
        && this.searchInputFilter(client);
    },
    isInsideRange(start, end) {
      return new Date(start) < new Date(end);
    },
    searchInputFilter({ labels, name, username }) {
      if (!this.searchText.trim()) {
        return true;
      }

      const regex = new RegExp(this.searchText, 'gi');
      return labels.some(
        ({ value, company }) => regex.test(value) && company === this.user.company,
      ) || regex.test(name) || regex.test(username);
    },
    findAndUpdate(conversation, index) {
      if (index !== -1) {
        const { campaignId } = this.query;
        if (conversation.campaign.id !== campaignId) {
          this.rows.splice(index, 1);
        } else {
          this.$set(this.rows, index, conversation);
        }
      } else if (this.actualPage === 1) {
        this.rows.unshift(conversation);
      }
    },
    findByClientInfo(props) {
      this.searchText = props;
      this.fetchMessenger(this.filters);
    },
    selectStatus(status) {
      this.selectedStatus = status.title;
    },
  },
};

</script>

<style scoped lang="scss">
@import "~styles/views/_messages.scss";
</style>
